import Bugsnag, { type Config, type Plugin } from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { BugsnagPluginCustomError } from '@sonicgarden/bugsnag-plugin-custom-error'

// NOTE: safariではjsが別ドメイン配信の場合にwindow.onerrorでキャッチするエラーが通知されないという問題の暫定対応
const BugsnagPluginAppleCrossOriginError: Plugin = {
  name: 'bugsnag-plugin-apple-crossorigin',

  load(client): void {
    if (!window.navigator.vendor.toLowerCase().includes('apple')) {
      return
    }

    const prevOnError = window.onerror
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    window.onerror = (messageOrEvent, url, lineNo, charNo, error) => {
      // SEE: https://github.com/bugsnag/bugsnag-js/blob/050d51dd699f64a27291d66d9beac0f74b4bf017/packages/plugin-window-onerror/onerror.js#L11
      if (lineNo === 0 && typeof messageOrEvent === 'string' && /Script error\.?/.test(messageOrEvent)) {
        const handledState = { severity: 'error', unhandled: true, severityReason: { type: 'unhandledException' } }
        const event = client.Event.create(new Error(messageOrEvent), true, handledState, 'apple crossorigin onerror', 1)
        client._notify(event)
      }

      prevOnError?.apply(this, [messageOrEvent, url, lineNo, charNo, error])
    }
  },
}

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const loggerOptions = (): Pick<Config, 'logger'> => {
  // eslint-disable-next-line unicorn/no-null
  return import.meta.env.RAILS_ENV === 'test' ? { logger: null } : {}
}

const userOptions = (): Pick<Config, 'user' | 'metadata'> => {
  if (!window.APP.rails.userData) return {}

  const { id, name, email, companyId } = window.APP.rails.userData
  return {
    user: { id, name, email },
    metadata: {
      company: {
        id: companyId,
      },
    },
  }
}

const customErrorPlugin = new BugsnagPluginCustomError({
  NetworkError: [
    'Failed to fetch',
    'Load failed',
    'Network Error',
    'NetworkError when attempting to fetch resource',
    'Request aborted',
    'SSLエラーが起きたため、サーバへのセキュリティ保護された接続を確立できません。',
    'The network connection was lost.',
    'timeout of 0ms exceeded',
    'インターネット接続がオフラインのようです。',
    'キャンセルしました',
    'ネットワーク接続が切れました。',
    '応答を解析できません',
  ],
  MemoryError: [
    'out of memory',
    'この操作を完了するための十分な記憶域がありません。',
    'メモリ リソースが不足しているため、この操作を完了できません。',
    'メモリが不足しています。',
  ],
  // NOTE: ここのグルーピングだけ上手く効いていない気がする
  IgnoreSyntaxError: [
    'Unexpected end of input',
    '終了していない文字列型の定数です。',
    ";' がありません。",
    "')' がありません。",
    "'}' がありません。",
  ],
  BadGatewayError: ['Request failed with status code 502'],
  ResizeObserverError: ['ResizeObserver loop limit exceeded'],
  LikelyBotError: [() => window.location.pathname === '/users/sign_in'],
  LocalFileError: [() => /^\/[A-Z]:/.test(window.location.pathname)],
  InvalidAuthenticityTokenError: ['ActionController::InvalidAuthenticityToken'],
  InvalidTimezoneError: ['timezone:'],
  ApolloInvariantViolationError: ['Invariant Violation:'],
  DynamicallyImportedError: ['error loading dynamically imported module'],
  PreloadError: ['Unable to preload'],
})

// NOTE: ブラウザ依存のシンタックスエラー検知の為にもエントリーポイントは分けておきたいが、他からも利用したいケースがあるため
window.bugsnagClient = Bugsnag.start({
  apiKey: 'b3faa6cf5dd35ed9bff8761d95abbf18',
  releaseStage: import.meta.env.RAILS_ENV,
  enabledReleaseStages: ['production', 'staging'],
  plugins: [new BugsnagPluginVue(), customErrorPlugin, BugsnagPluginAppleCrossOriginError],
  onError: () => {
    return !window.APP.rails.ignoredBugsnag
  },
  ...userOptions(),
  ...loggerOptions(),
})
